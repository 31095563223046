var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseThemeComponent',{attrs:{"bg-color":"#0097ce","full-bleed-bg":"","title":"Follow Us @trepsnola","title-class":"text-xs-center","name":"PageSection","section-name":"home_ig"}},[_c('VLayout',{attrs:{"wrap":""}},[_c('RestoInstagram',{staticClass:"layout row wrap",attrs:{"token":_vm.getIgAccessToken,"count":['xs', 'sm'].includes(_vm.$mq) ? 4 : 8,"mediatypes":['IMAGE', 'VIDEO', 'CAROUSEL_ALBUM']},scopedSlots:_vm._u([{key:"feeds",fn:function(ref){
var feed = ref.feed;
return [_c('VFlex',{style:({
            'padding-top': _vm.imgPadding[_vm.$mq],
            position: 'relative',
          }),attrs:{"grow":"","xs12":"","sm6":"","md3":""}},[_c('a',{staticClass:"img-elem",style:({
              top: 0,
              left: 0,
              height: 'calc( 100% - 30px )',
              width: 'calc( 100% - 30px )',
              margin: '15px',
              position: 'absolute',
              'background-image': ("url(" + (feed.media_type === 'VIDEO'
                  ? feed.thumbnail_url
                  : feed.media_url) + ")"),
              'background-size': 'cover',
              'background-position': 'center center',
              'text-align': 'center',
            }),attrs:{"href":feed.permalink,"target":"_blank"}},[(feed.media_type === 'VIDEO')?_c('VIcon',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%,-50%)"},attrs:{"color":"white","large":""}},[_vm._v("play_circle_filled ")]):_vm._e()],1)])]}},{key:"error",fn:function(ref){
            var error = ref.error;
return [(!!error)?_c('VFlex',{attrs:{"text-xs-center":""}},[(_vm.loggedIn)?_c('div',[_vm._v(" "+_vm._s(error)+" ")]):_c('div',[_vm._v("The Instagram feed couldn't be loaded.")])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }